@use "variables/layout";
@use "variables/color";

.titleContainer {
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: calc(layout.$gutter / 2);
  margin-bottom: 16px;

  > *:first-child {
    flex: 1;
  }

  @media (min-width: 992px) {
    margin-bottom: 26px;
  }
}

.title {
  font-weight: bold;
  font-size: 18px;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 26px;
  }

  @media (min-width: 992px) {
    text-align: left;
  }
}

.more {
  color: color.$primaryBlue;
  display: block;
  font-size: 16px;

  @media (min-width: 768px) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
