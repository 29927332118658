@use "variables/color";
@use "mixins/breakpoint";

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 12px 32px;
  background: var(--btn-color);
  border: solid 1px #0000;
  border-radius: 4px;
  color: var(--btn-text-color);
  font-size: 12px;
  line-height: 16px;
  font-family: inherit;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  --btn-alt-color: #0000;
  --btn-alt-hover-color: #0000;

  &:hover {
    background-color: var(--btn-hover-color);
    box-shadow: 0 4px 10px 0 transparentize(color.$black, 0.75);
    cursor: pointer;
  }

  &:disabled {
    background-color: color.$gray;
    color: color.$white;
    cursor: not-allowed;
  }

  &.fluid {
    width: 100%;
  }

  &.blue {
    --btn-color: #{color.$primaryBlue};
    --btn-hover-color: #{lighten(color.$primaryBlue, 10%)};
    --btn-text-color: #{color.$white};
  }

  &.club {
    --btn-color: #{color.$club};
    --btn-hover-color: #{lighten(color.$club, 10%)};
    --btn-text-color: #{color.$white};
  }

  &.white {
    --btn-color: #{color.$white};
    --btn-hover-color: #{darken(color.$white, 10%)};
    --btn-text-color: #{color.$almostBlack};
    --btn-alt-color: #{transparentize(color.$white, 0.8)};
    --btn-alt-hover-color: #{transparentize(color.$white, 0.7)};
  }

  &.opaque {
    --btn-color: #{transparentize(color.$white, 0.6)};
    --btn-hover-color: #{transparentize(color.$white, 0.5)};
    --btn-text-color: #{color.$white};
  }

  &.outlined {
    background-color: var(--btn-alt-color);
    border-color: var(--btn-color);
    color: var(--btn-color);

    &:hover {
      background-color: var(--btn-alt-hover-color);
      border-color: var(--btn-hover-color);
      color: var(--btn-hover-color);
    }

    &:disabled {
      border-color: color.$gray;
      color: color.$gray;
    }
  }

  .content {
    display: inline-flex;
    align-items: center;
  }

  @include breakpoint.tablet {
    font-size: 14px;
    padding: 14px 32px;
  }

  &.loading {
    .content {
      opacity: 0;
    }

    .loaderWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .loader {
      width: 16px;
      height: 16px;
      color: inherit;

      @include breakpoint.tablet {
        width: 24px;
        height: 24px;
      }
    }
  }
}
