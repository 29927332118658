@use "variables/color";

.iconWrapper {
  position: relative;
}

.badge {
  position: absolute;
  top: -4px;
  left: 50%;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: color.$almostBlack;
  padding: 1px 5px;
  border-radius: 7px;
  background-color: color.$green;
}
