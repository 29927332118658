.title {
  display: grid;
  grid-column: 1/3;
  grid-template-columns: repeat(2, auto);
  align-items: start;
  flex-basis: 100%;
  justify-content: start;
  gap: 16px;
  padding: 4px 0;
  color: #fff;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}

.description {
  font-size: 0.75em;
  font-weight: 400;
  margin: 0;
  opacity: 0.5;
}

.link {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;
  color: #fff;
  font-weight: 700;
  text-align: left;
  text-decoration: none;

  svg {
    color: #33adff;
  }
}

.logoutButtonWrapper {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  align-self: start;
  justify-items: center;
  padding: 40px 0 74px;
}

.logoutButton {
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: auto 1fr;
  color: #bbb7c5;
  text-align: left;

  svg {
    color: inherit;
  }
}
