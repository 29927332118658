@use "variables/color";

.button {
  align-items: center;
  background: color.$primaryBlue;
  border: 0;
  border-radius: 50%;
  bottom: 34px;
  color: color.$white;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 0;
  position: fixed;
  right: 34px;
  transform: translateY(200%);
  transition: transform 0.25s ease-in-out;
  width: 48px;
  z-index: 10;

  &.visible {
    transform: translateY(0);
  }

  @media (min-width: 1524px) {
    left: calc(50% + 688px);
    right: auto;
  }
}
