.teaserList {
  display: none;
  padding: 0;

  @media (min-width: 992px) {
    display: block;
  }
}

.mobileGrid {
  display: grid;
  grid-gap: 24px 16px;
  grid-template-columns: repeat(auto-fit, 164px);
  justify-content: center;

  @media (min-width: 992px) {
    display: none;
  }
}
