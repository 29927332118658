@use "variables/color";

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: lighten(color.$black, 45%);
}

.titleBox {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 24px auto 18px;

  &:first-child {
    margin-top: 10px;
  }

  @media (min-width: 992px) {
    margin: 24px auto 16px;

    &:first-child {
      margin-top: 0;
    }
  }
}
