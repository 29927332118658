@use "variables/color";

.link {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  color: color.$white;
  cursor: pointer;

  &.accent {
    color: color.$primaryBlue;
    font-weight: 700;
  }
}

.title {
  display: grid;
  grid-column: 1/3;
  grid-template-columns: repeat(2, auto);
  align-items: start;
  justify-content: start;
  flex-basis: 100%;
  gap: 16px;
  padding: 4px 0;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  color: #fff;
}
