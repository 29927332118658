@use "variables/color";

.dropdown {
  position: relative;
  font-size: 0;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.list {
  position: absolute;
  right: 0;
  top: 100%;
  padding: 0;
  background-color: color.$white;
  border-radius: 4px;
  font-size: 1rem;
}

.item {
  display: flex;
  text-align: left;
  padding: 10px 18px;
  width: 100%;
  background-color: color.$white;
  color: color.$almostBlack;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: rgba(91, 189, 255, 0.2);
  }

  &:active {
    background-color: rgba(51, 173, 255, 0.4);
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.itemContent {
  padding-top: 4px;

  span {
    display: block;
    white-space: nowrap;
  }
}

.description {
  font-size: 14px;
  color: #8d889e;
}

.icon {
  color: color.$primaryBlue;
  margin-right: 14px;
}
