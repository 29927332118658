@use "mixins/breakpoint";

.tooltip {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: calc(100% - 30px);
  max-width: 175px;
  padding: calc(var(--paddingBlock, 8) * 1px) 8px;
  color: #989898;
  background: #fff;
  border: 1px solid #bbb7c5;
  border-radius: 8px;
  font-size: 14px;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  opacity: 0;
  visibility: hidden;

  &__visible {
    opacity: 1;
    visibility: visible;
  }

  @include breakpoint.tablet {
    font-size: 12px;
  }
}
