@use "mixins/breakpoint";
@use "variables/color";

.modalHeader {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.modalTitle {
  text-align: center;
  margin: 0;
}

.modalDescription {
  text-align: center;
  margin: 0;
  color: var(--desciption-color, #{color.$almostBlack});
}

.modalFooter {
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-direction: column;

  &__full {
    & > * {
      flex: 1;
    }
  }

  @include breakpoint.tablet {
    flex-direction: row;
  }
}

.dialogOverlay {
  position: fixed;
  inset: 0;
  padding: 60px 16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  backdrop-filter: blur(2px);
  background: rgba(22, 8, 41, 0.5);
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 100;
}

.dialogContent {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: var(--color, #{color.$black});
  background: var(--background, #{color.$white});
  height: fit-content;
  max-width: 320px;
  max-height: 100%;
  padding: 32px 16px;
  border-radius: 16px;
  overflow: auto;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  &__dark {
    --background: #{color.$almostBlack};
    --color: #{color.$white};
    --desciption-color: #{color.$veryLightGray};
  }

  &:focus {
    outline: none;
  }

  @include breakpoint.tablet {
    padding: 32px;
    min-width: 500px;
    width: 90%;
    max-width: var(--max-width, 716px);

    &__small {
      --max-width: 518px;
    }
  }
}

.closeButton {
  display: flex;
  position: absolute;
  color: var(--color);
  --offset: 12px;
  right: var(--offset);
  top: var(--offset);

  @include breakpoint.tablet {
    --offset: 16px;
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: scale(0.96);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
