@use "sass:map";

@use "variables/layout";
@use "variables/color";
@use "mixins/breakpoint";

.topBar {
  color: color.$white;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;

  @media (min-width: 992px) {
    --top-bar-height: #{map.get(layout.$topBarHeight, "desktop")};
  }

  --mobile-search-input-height: 64;
  --top-bar-height: calc(#{map.get(layout.$topBarHeight, "mobile")} + var(--mobile-search-input-height) * 1px);

  --search-input-border-color: rgba(255, 255, 255, 0.5);
  --search-button-color: #{color.$white};
  --search-button-bg-color: transparent;
  --item-visibility: visible;
  --item-display: block;
}

.searchVisible {
  --search-input-border-color: #{color.$white};
  --search-button-color: #{color.$black};
  --search-button-bg-color: #{color.$white};
  --item-visibility: hidden;
  --item-display: none;
}

.mobileInputHidden {
  --mobile-search-input-height: 0;
}

.topBarBackground {
  background: color.$almostBlack;
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  height: map.get(layout.$topBarHeight, "mobile");

  @media (min-width: 992px) {
    flex-direction: row;
    height: map.get(layout.$topBarHeight, "desktop");
  }

  @media (min-width: 1524px) {
    overflow: visible;
  }
}

.wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.column {
  align-items: center;
  display: grid;
  flex-shrink: 0;
  gap: 15px;
  grid-template-columns: repeat(2, auto);
  height: 100%;
  justify-content: start;
}

.catalogButton {
  visibility: var(--item-visibility);
  display: none;

  @media (min-width: 768px) {
    align-items: center;
    background: transparent;
    border: none;
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.2);
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    font-size: 18px;
    padding: 8px 0 8px 15px;
  }

  svg {
    margin-left: 15px;
    transform: rotateZ(0deg);
    transition: transform 0.2s ease;
  }

  &.open {
    svg {
      transform: rotateZ(-180deg);
    }
  }
}

.catalogMobileButton {
  display: var(--item-display);
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;

  & > svg {
    display: flex;
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.columnWithIcons {
  position: relative;
  visibility: var(--item-visibility);
  justify-content: flex-end;
  gap: 0;
}

.logo {
  display: block;
  height: 20px;

  @media (min-width: 768px) {
    height: 24px;
  }
}

.logoLink {
  display: block;
  padding: 12px 0;
}

.closeSearchButton {
  position: absolute;
  right: 0;
  display: none;
  visibility: hidden;
  text-decoration: underline;
  font-size: 14px;

  &.visible {
    display: block;
    visibility: visible;
  }
}
