@use "ui/colors";

.clubBadge {
  color: colors.$club-500;
  align-self: center;
  justify-self: flex-end;
  grid-area: price;
  margin-top: 4px;
}

.cycleBadge {
  align-self: center;
  grid-area: rating;
}

.lowestPrice {
  font-size: 10px;
  font-weight: 400;
  margin-top: 10px;
  grid-column: 1 / -1;

  @media (min-width: 992px) {
    font-size: 12px;
  }
}

.price {
  display: flex;
  align-items: flex-end;
  justify-self: flex-end;
  column-gap: 8px;
  grid-area: price;
  color: colors.$purple-500;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin: 4px 0 0;

  &:not(:first-child)::before {
    align-self: center;
    background: colors.$gray-700;
    content: "";
    display: block;
    width: 1px;
    height: 15px;
  }

  &[data-base-price] {
    color: colors.$blue-500;

    &::after {
      color: colors.$gray-700;
      content: attr(data-base-price);
      font-size: 14px;
      font-weight: 400;
      text-decoration: line-through;
    }
  }

  &__colorless {
    color: inherit;

    &[data-base-price]::after {
      color: inherit;
    }
  }
}

.priceWithClubBadge {
  grid-area: price;
  justify-self: flex-end;
  display: flex;
  gap: 8px;
  align-items: center;
}

.rating {
  display: flex;
  align-items: center;
  column-gap: 6px;
  grid-area: rating;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
}
