::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(22, 8, 41, 0.2);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(22, 8, 41, 0.4);
}
