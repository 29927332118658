@use "variables/layout";
@use "variables/color";

.carusel {
  position: relative;
}

.caruselSlider {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  grid-gap: #{layout.$gutter / 2};
  margin: 0 -#{layout.$gutter / 2};
  padding: 0 #{layout.$gutter / 2};

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  &.compact {
    @media (min-width: 1280px) {
      grid-gap: #{layout.$gutter * 1.5};
    }
  }
}

.sliderItem {
  display: flex;
  flex-shrink: 0;
  position: relative;
  z-index: 0;
  scroll-snap-align: center;
  width: 220px;

  @media (min-width: 576px) {
    min-width: 50%;
    flex-shrink: 1;
    scroll-snap-align: start;

    &:first-child {
      scroll-snap-align: center;
    }
  }

  @media (min-width: 768px) {
    min-width: 33.3333%;
  }

  @media (min-width: 992px) {
    min-width: 20%;
  }

  @media (min-width: 1524px) {
    min-width: 16.6666%;
  }

  &.compact {
    width: 140px;

    @media (min-width: 1280px) {
      width: 180px;
    }
  }

  > * {
    flex: 1;
  }
}

.arrow {
  z-index: 1;
  position: absolute;
  top: 124px;
  display: none;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(200px);
  width: 46px;
  height: 46px;
  padding: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.5);
  border: 0;
  border-radius: 50%;
  transition: opacity 0.5s ease;
  transform: translateY(-50%);
  cursor: pointer;

  &:disabled {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  &.right {
    right: -#{layout.$gutter / 2};
  }

  &.left {
    left: -#{layout.$gutter / 2};
  }

  svg {
    color: color.$black;
  }

  @media (min-width: 576px) {
    display: flex;
  }

  @media (min-width: 768px) {
    top: 110px;
  }
  @media (min-width: 992px) {
    top: 87px;
  }
  @media (min-width: 1200px) {
    top: 105px;
  }
  @media (min-width: 1524px) {
    top: 116px;
  }

  &.compact {
    top: 70px;

    @media (min-width: 1280px) {
      top: 90px;
    }
  }
}

.arrowLeft {
  transform: scaleX(-1);
}
