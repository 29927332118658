@use "variables/layout";
@use "mixins/breakpoint";

.container {
  width: 100%;
  margin: 0 auto;
  max-width: 1500px;
  padding: 0 (layout.$gutter / 2);

  &.compact {
    max-width: 1200px;
  }

  &.full {
    max-width: 100%;
  }
}
