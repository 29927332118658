@use "mixins/breakpoint";
@use "ui/colors";

.iconButtons {
  color: colors.$blue-500;
}

.list {
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  align-items: center;
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.listItem {
  display: none;

  &:hover {
    color: #fff;
  }

  @include breakpoint.tablet {
    display: flex;
  }

  &.showOnMobile {
    display: flex;
  }

  &.showOnMobileOnly {
    display: flex;

    @include breakpoint.tablet {
      display: none;
    }
  }
}

.loginButton {
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: colors.$violet-500;
  color: #fff;
  border-radius: 4px;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  padding: 6px 8px;

  &:hover {
    background: colors.$violet-400;
  }
}

.menuLink {
  color: inherit;
}

.icon {
  display: flex;
}

.cartButton {
  position: relative;
}
