.button {
  color: inherit;
  display: inline-block;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  appearance: none;
}
