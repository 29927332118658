@use "variables/color";
@use "mixins/breakpoint";

.titleIcon {
  color: color.$green;
  margin-right: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include breakpoint.tablet {
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
  }
}

.cover {
  width: 150px;
  border-radius: 4px;
  margin-right: 0;
  aspect-ratio: 1/1;

  @include breakpoint.tablet {
    margin-right: 20px;
  }
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;

  @include breakpoint.tablet {
    gap: 4px;
    margin-top: 12px;
  }
}

.title {
  margin: 0;
}

.price {
  margin: 8px 0 0;

  @include breakpoint.tablet {
    margin: 12px 0 0 40px;
  }
}

.author {
  margin: 0;
}

.lineClamp {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
