@use "sass:color";
@use "ui/colors";

.actionBtn {
  background: colors.$blue-500;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  grid-area: cover;
  align-self: flex-end;
  justify-self: flex-end;
  position: relative;
  padding: 8px;
  height: 36px;
  width: 36px;
  margin: 0 8px 8px 0;
  transition: color 0.25s ease, opacity 0.25s ease, box-shadow 0.25s ease;
  z-index: 3;

  @media (min-width: 992px) {
    opacity: var(--action-button-opacity, 0);
  }

  > svg {
    &:last-child:not(:first-child) {
      display: none;
    }
  }

  &:hover {
    background: colors.$blue-400;
    box-shadow: 0 4px 8px 0 color.adjust(colors.$violet-900, $alpha: -0.8);

    > svg {
      &:first-child:not(:last-child) {
        display: none;
      }

      &:last-child:not(:first-child) {
        display: block;
      }
    }
  }

  &:active {
    background: colors.$blue-500;
    box-shadow: 0 2px 4px 0 color.adjust(colors.$violet-900, $alpha: -0.7);
  }
}

.author {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-area: author;
}

.cover {
  display: block;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  font-size: 0;
  grid-area: cover;
  aspect-ratio: 1/1;
}

.coverImage {
  transform: scale(var(--cover-scale, 1));
  transform-origin: center;
  transition: transform 1s ease-in-out;
  width: 100%;
}

.link {
  color: inherit;
  text-decoration: none;
  display: grid;
  align-items: center;
  grid-column: 1 / -1;
  grid-row: 1 / 6;
  grid-template-columns: subgrid;
  grid-template-rows: subgrid;
  grid-template-areas: inherit;
}

.teaser {
  --accent-color: #{colors.$violet-900};
  color: colors.$gray-700;
  align-self: stretch;
  align-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-areas:
    "cover cover"
    "title title"
    "author author"
    "rating price"
    "lowest-price lowest-price";
  column-gap: 4px;
  overflow: hidden;
  position: relative;
  isolation: isolate;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      --cover-scale: 1.05;
      --action-button-opacity: 1;
    }
  }

  &__colorless {
    --accent-color: inherit;
    color: inherit;
  }
}

.title {
  color: var(--accent-color);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 8px 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-area: title;
}
