@use "variables/color";

.menuRoot {
  background: color.$almostBlack;
  border: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 16px 16px 0;
  position: fixed;
  top: 0;
  transform: translateX(-101%);
  transition: transform 0.2s ease;
  width: 100%;
  z-index: 100;

  &[open] {
    transform: translateX(0);
  }

  @media (min-width: 768px) {
    display: none;
  }
}
