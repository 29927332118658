@use "sass:map";

@use "ui/colors";
@use "variables/layout";
@use "mixins/breakpoint";

.container {
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 2147483647;
}

.viewport {
  --viewport-padding: 16px;
  position: absolute;
  pointer-events: auto;
  top: #{map.get(layout.$topBarHeight, "mobile")};
  right: 0;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: calc(330px + 2 * var(--viewport-padding));
  max-width: 100vw;
  margin: 0;
  list-style: none;
  outline: none;

  @include breakpoint.tablet {
    top: #{map.get(layout.$topBarHeight, "desktop")};
  }
}

.toast {
  position: relative;
  background-color: colors.$violet-900;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid colors.$violet-400;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 12px;
  display: grid;
  color: var(--color, colors.$gray-50);
  grid-template-areas:
    "icon title close"
    ". actions actions";
  grid-template-columns: auto 1fr auto;
  gap: 4px;
  align-items: center;
  &[data-state="open"] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  &[data-state="closed"] {
    animation: hide 100ms ease-in;
  }
  &[data-swipe="move"] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }
  &[data-swipe="cancel"] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }
  &[data-swipe="end"] {
    animation: swipeOut 100ms ease-out;
  }

  &__message {
    grid-template-areas:
      "icon title close"
      ". description ."
      ". actions actions";
  }

  &__success {
    --color: #{colors.$green};
  }
  &__warning {
    --color: #{colors.$orange};
  }
  &__error {
    --color: #{colors.$red-light};
  }
  &__info {
    --color: #{colors.$blue-500};
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--color);
    display: block;
    height: 4px;

    animation-name: barCounter;
    animation-timing-function: linear;
    animation-duration: var(--duration);
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}

.title {
  grid-area: title;
  display: flex;
  align-items: center;
  column-gap: 4px;
  color: var(--color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.titleIcon {
  grid-area: icon;
}

.description {
  grid-area: description;
  color: colors.$gray-50;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
}

.actions {
  margin-top: 4px;
  margin-left: auto;
  grid-area: actions;
}

.actionButton {
  min-width: 100px;
  padding: 6px 4px;
}

.closeButton {
  grid-area: close;
  color: colors.$gray-50;
  display: flex;
  align-self: start;
}

@keyframes barCounter {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
