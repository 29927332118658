@use "variables/color";

.backdrop {
  background: transparentize(color.$black, 0.3);
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  touch-action: none;
}

.container {
  padding-bottom: 16px;
  padding-top: 16px;

  @media (min-width: 576px) and (max-width: 991px) {
    max-width: 556px;
  }

  @media (min-width: 992px) {
    padding-bottom: 32px;
    padding-top: 32px;
  }

  &:empty {
    padding: 0;
  }
}

.content {
  background: color.$white;
  max-height: 100%;
  overflow-y: auto;
  overscroll-behavior: contain;

  @media (min-width: 992px) {
    border-radius: 0 0 16px 16px;
  }
}

.wrapper {
  position: absolute;
  width: 100%;
  height: calc(100dvh - var(--top-bar-height));
  z-index: -1;
}
