@use "variables/color";

.footer {
  background: color.$almostBlack;
  color: color.$white;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    grid-gap: 28px;
  }
}

.columns {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: 1fr;
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1200px) {
    display: flex;
  }
}

.column {
  display: grid;
  grid-template-columns: 1fr;
  align-content: start;
  gap: 20px;

  @media (min-width: 1200px) {
    flex: 1;
  }
}

.title {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  margin: 0;

  @media (min-width: 768px) {
    font-size: 20px;
  }

  &.expandable {
    &::after {
      border: solid currentColor;
      border-width: 0 2px 2px 0;
      content: "";
      display: block;
      height: 10px;
      transition: all 0.2s ease;
      transform: translateY(-25%) rotateZ(45deg);
      width: 10px;
    }

    @media (min-width: 768px) {
      &::after {
        cursor: default;
        display: none;
      }
    }

    &.expanded {
      &::after {
        transform: translateY(25%) rotateZ(-135deg);
      }
    }
  }
}

.linkList {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;

  &.expandable {
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr;
    height: 0;
    overflow: hidden;
    padding-bottom: 0;

    @media (min-width: 768px) {
      border-bottom: 0;
      height: auto;
      padding-bottom: 18px;
    }
  }

  &.expanded {
    height: auto;
    padding-bottom: 18px;
  }
}

.link {
  color: inherit;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    color: inherit;
    opacity: 0.6;
  }
}

.linkBtn {
  display: block;
  font-size: 16px;
  text-align: left;

  &:hover {
    opacity: 0.6;
  }
}

.appLinkList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.appLinkItem {
  display: flex;
  width: 162px;
}
