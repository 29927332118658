.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1524px) {
    max-width: 1500px;
  }

  &.full {
    max-width: unset;
  }

  &.extended {
    @media (min-width: 1524px) {
      max-width: 1832px;
    }
  }
}
