@use "mixins/breakpoint";

.searchInputForm {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;

  &.mobile {
    --input-height: 48px;
    margin-bottom: var(--margin);
    transition: margin var(--hide-transition-time);

    @media (min-width: 992px) {
      display: none;
    }
  }

  &.desktop {
    --input-height: 40px;
    display: none;

    @media (min-width: 992px) {
      display: flex;
      margin: 0 40px;
      max-width: 600px;
    }
  }
}

.mobileContainer {
  --hide-transition-time: 300ms;
  --margin: 16px;
  display: grid;
  grid-template-rows: 1fr;
  opacity: 1;
  transition: grid-template-rows var(--hide-transition-time), opacity 600ms;

  &.hidden {
    --margin: 0;
    grid-template-rows: 0fr;
    opacity: 0;
  }

  & > * {
    overflow: hidden;
  }
}

.input {
  outline: none;
  width: 100%;
  height: var(--input-height);
  background: none;
  border: 1px solid var(--search-input-border-color);
  border-radius: 8px;
  color: #fff;
  padding: 8px calc(var(--input-height) + 32px) 8px 16px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
}

.buttonsWrapper {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clearButton {
  color: #979797;
  margin-right: 8px;

  svg {
    display: flex;
  }
}

.searchButton {
  border-radius: 8px;
  height: var(--input-height);
  width: var(--input-height);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--search-button-color);
  background-color: var(--search-button-bg-color);

  &[disabled] {
    pointer-events: none;
  }
}
