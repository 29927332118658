@use "variables/color";

.catalogDropdown {
  display: none;

  @media (min-width: 768px) {
    background: color.$almostBlack;
    border-radius: 0 0 8px 8px;
    box-shadow: inset 0 1px 0 transparentize($color: color.$white, $amount: 0.8);
    display: flex;
    flex-direction: column;
    height: auto;
    left: 0;
    overflow: hidden;
    position: absolute;
    padding: 0;
    right: 0;
    top: 100%;
    transform: translateY(-101%);
    visibility: hidden;
    transition: transform 0.2s ease, visibility 0.2s ease;
    width: auto;
    z-index: -1;

    &.open {
      transform: translateY(0);
      visibility: visible;
    }
  }
}

.list {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 16px;
  justify-items: start;
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  padding-bottom: 16px;
  --columns-number: 2;

  @media (min-width: 768px) {
    height: auto;
    grid-auto-flow: column;
    padding: 24px 24px 16px;
    grid-template-rows: repeat(calc(var(--items-length) / var(--columns-number) + 0.5), auto);
  }

  @media (min-width: 992px) {
    --columns-number: 3;
  }

  @media (min-width: 1200px) {
    --columns-number: 4;
  }
}

.item {
  color: color.$white;
  text-decoration: none;

  &.accent {
    color: color.$primaryBlue;
  }

  &:hover {
    color: color.$lightBlue;
  }
}

.footer {
  background: #24113e;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 5px 24px;
}

.footerItem {
  align-items: center;
  color: color.$gray;
  cursor: pointer;
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  text-decoration: none;

  &:hover {
    color: color.$white;
  }
}

.collapseButton {
  composes: footerItem;
  padding: 0;
  background: none;
  border: none;
}
