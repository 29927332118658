@use "variables/color";

.close {
  color: color.$darkGray;
  cursor: pointer;
  justify-self: flex-end;
}

.header {
  align-items: center;
  border-bottom: 1px solid rgba(187, 183, 197, 0.2);
  display: grid;
  gap: 10px 26px;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  padding-bottom: 12px;
}

.logo {
  height: 22px;
  width: 94px;
}
