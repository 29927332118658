@use "variables/color";

.footerCatalog {
  margin-bottom: 20px;
  background-color: color.$almostBlack;
  width: 100%;
  align-self: center;
  align-content: start;
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 24px;

  @media (min-width: 768px) {
    margin-bottom: 0px;
    background: color.$darkViolet;
  }
}

.list {
  &.expandable {
    grid-auto-flow: row;
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
      grid-auto-flow: column;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(13, auto);
      padding-bottom: 24px;
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(7, auto);
    }
  }
}

.item {
  color: color.$white;

  &:hover {
    color: #9dd7ff;
  }

  &.accent {
    color: color.$primaryBlue;
  }
}
