@use "variables/color";

.loader {
  display: block;
  width: 46px;
  height: 46px;
  color: color.$club;

  svg {
    animation-name: rotate;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 1s;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
