* {
  -webkit-font-smoothing: antialiased;
}

body {
  overscroll-behavior-x: none;
  --scrollbar-width: 12px;
}

.grecaptcha-badge {
  visibility: hidden;
}
